import Star from '../assets/star.svg';

const About = () => {
    return(
        <>
        <div className="w-full h-[80vh] flex flex-col justify-between lg:items-center pt-10 pl-6 pr-6 pb-6 lg:relative lg:top-[200px]">
            <div className='flex flex-col md:h-[300px] lg:h-[400px] justify-between mb-6 lg:p-6 border-1 lg:border'>
                <p className="md:max-w-[450px] pb-6 text-xs md:text-base">
                Silver Dagger is an independent record label based in Stockholm, founded in 2023 by the members of Swedish psych rock band MaidaVale and Lisa Hellberg. We believe in authenticity, free creativity and an equal music scene. 
                <br></br>
                <br></br>Get in touch!
                </p>
                <div>
                    <h2 className="font-bold pb-2">contact</h2>
                    <div className='text-sm md:text-base'>
                        <p>Label: <span className="underline">schysstasthlm[at]gmail.com</span></p>
                        <p>Distribution: <span className="underline">pelle[at]bordermusic.eu</span></p>
                        <p>PR Germany: <span className="underline">info[at]noisolution.de </span></p>
                        <p>PR Rest of the World: <span className="underline">schysstasthlm[at]gmail.com</span></p>
                    </div>
                </div>
            </div>
            <div className="text-xs hello flex flex-col items-start lg:items-center mb-5 md:mb-10">
                <img className='pb-3' src={Star}></img>
                <p>Schyssta Skivor Stockholm AB, 559451-2971</p>
                <p>Stockholm, Sweden</p>
                <p className="text-xs pt-2">© 2024 SilverDagger Records</p>
            </div>
            
        </div>
        </>
    )
}

export default About;